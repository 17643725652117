import React from "react";
import ImageCard from "../components/ImageCard";
import "./global.css";
import reportPDF from "../assets/VDG-25_1_2.pdf";

const images = [
  {
    image: "/assets/modrakniha.jpg",
    title: "Gabčíkovská časť hydroenergetického projektu",
    description: " 1995",
    link: "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/blue/index.html",
  },
  {
    image: "/assets/hnedakniha.jpg",
    title: "Gabčíkovská časť hydroenergetického projektu",
    description: " 1999",
    link: "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/brown/index.html",
  },

  {
    image: "/assets/zelenakniha.jpg",
    title: "Návšteva oblasti vodného diela Gabčíkovo",
    description: " 1999",
    link: "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/green/index.html",
  },
  {
    image: "/assets/zltakniha.jpg",
    title:
      "Optimalizácia vodného režimu ramennej sústavy v úseku Dunaja Dobrohošť- Sap",
    description: " 2003",
    link: "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/yellow/index.htm",
  },
  {
    image: "/assets/zbornik04.jpg",
    title: "Vodné dielo Gabčíkovo a prírodné prostredie ",
    description: " 2004",
    link: "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/zbornik_04/index.html",
  },
  {
    image: "/assets/image.jpg",
    title: "Slovensko-maďarský monitoring životného prostredia na Dunaji ",
    description: "2006",
    link: "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/moson/index.htm",
  },
  {
    image: "/assets/SEA_info.jpg",
    title: "Návrhy na riešenie starého koryta Dunaja v úseku Čunovo–Sap",
    description: " 2012",
    link: "https://gabcikovo.gov.sk/uploads/monitorovacie_spravy/SEA_info_web.pdf",
  },
  {
    image: "/assets/pres_gm.jpg",
    description: "2017",
    title: "Vodné dielo Gabčíkovo a spoločný slovensko-maďarský monitoring ",

    link: "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/present/gab_monit_en_ppt.pdf",
  },
  {
    image: "/assets/nato_cover.jpg",
    title: "Procesy kvality podzemnej vody po brehovej infiltrácii",
    description: "2002",
    link: "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/NATO/nato.htm",
  },
  {
    image: "/assets/nato06_cover.jpg",
    title: "Vplyv kolmatácie riečneho dna na podzemné vody",
    description: "2006",
    link: "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/Nato_06/nato.htm",
  },
  {
    image: "/assets/VDG-25_1_1.jpg",
    title: "Vodné dielo Gabčíkovo a prírodné prostredie",
    description: " 2017",
    link: reportPDF, // Použitie importovaného PDF súboru
    isPDF: true,
  },
];

const Zborniky = () => {
  return (
    <section className="zborniky-section">
      <header>
        <h2>Zborníky a prezentácie</h2>
      </header>
      <div className="gallery">
        {images.map((item, index) => (
          <ImageCard
            key={index}
            image={item.image}
            title={item.title}
            description={item.description}
            link={item.link}
            isPDF={item.isPDF}
          />
        ))}
      </div>
    </section>
  );
};

export default Zborniky;
