import React from "react";
import "./Contact.css"; // Odkaz na CSS nižšie

function Contact() {
  return (
    <div className="contact-page">
      <h1>Kontakty</h1>

      {/* Môžeš pridať krátky popis / nadpis */}
      <p className="contact-intro">
        Tu nájdete kontaktné údaje našich odborníkov a spoločosti.
      </p>
      <h1></h1>

      <div className="contacts-grid">
        <div className="contact-card">
          <br></br>
          <h2>RNDr. Zoltán HLAVATÝ, PhD.</h2>

          <p>
            Email:{" "}
            <a href="mailto:hlavaty@podzemnavoda.sk">hlavaty@podzemnavoda.sk</a>
          </p>
          <p>
            Tel: <a href="tel:+421905321203">+421 905 321 203</a>
          </p>
        </div>

        <div className="contact-card">
          <br></br>
          <h2>prof. RNDr. Igor MUCHA, DrSc.</h2>
          <p>
            Email:{" "}
            <a href="mailto:email@podzemnavoda.sk">email@podzemnavoda.sk</a>
          </p>
        </div>

        <div className="contact-card">
          <br></br>
          <h2>RNDr. Ľubomír BANSKÝ, PhD.</h2>
          <p>
            Email:{" "}
            <a href="mailto:bansky@podzemnavoda.sk">bansky@podzemnavoda.sk</a>
          </p>
        </div>
        <div className="contact-card ceo-card">
          <h3>Konateľ</h3>
          <h2>RNDr. Dalibor RODÁK, PhD.</h2>

          <p>
            Email:{" "}
            <a href="mailto:rodak@podzemnavoda.sk">rodak@podzemnavoda.sk</a>
          </p>
          <p>
            Tel: <a href="tel:+421905930091">+421 905 930 091</a>
          </p>
        </div>
      </div>

      <div className="company-section">
        <div className="company-info contact-company-info">
          <h4>Kontaktné údaje spoločnosti</h4>
          <br></br>
          <h5>Konzultačná skupina PODZEMNÁ VODA, spol. s r. o.</h5>
          <p className="kolinska">Kolískova 1</p>
          <p className="bratislava">841 05 Bratislava </p>
          <p className="slovakia">Slovenská republika</p>
          <p>
            Tel1: <a href="tel:+421905930091">+421 905 930 091</a>{" "}
          </p>
          <p>
            Tel2: <a href="tel:+421905321203">+421 905 321 203</a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:email@podzemnavoda.sk">email@podzemnavoda.sk</a>
          </p>
          <p>
            Web: <a href="https://www.podzemnavoda.sk">www.podzemnavoda.sk</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
