import React from "react";
import "../pages/global.css";

const ImageCard = ({ image, title, description, link }) => {
  return (
    <a
      className="image-card"
      href={link}
      target="_blank"
      rel="noopener noreferrer">
      <div className="image-container">
        <img className="image-card-img" src={image} alt={title} />
      </div>
      <h3>{title}</h3>
      {description && <p>{description}</p>}
    </a>
  );
};

export default ImageCard;
