import React from "react";
import "./global.css"; // Import CSS pre štýlovanie

const MedzinarodnaSpolupraca = () => {
  return (
    <div className="spolupraca-container">
      <h2>Medzinárodná spolupráca</h2>
      <ul className="spolupraca-list">
        <li>
          Odborné práce v rámci medzinárodného ekologického programu
          PHARE/EC/WAT/1: Podunajská nížina - model podzemných vôd.
        </li>
        <li>
          Návrh vodohospodárskych projektov a štúdia zabezpečenia vody pre mesto
          Saltillo v štáte Coahuila (spolupráca na projekte v Mexiku).
        </li>
        <li>
          Štúdia trvalo udržateľného rozvoja zdrojov podzemných vôd a prírodného
          prostredia v alúviu rieky Langat (spolupráca na projekte v Malajzii).
        </li>
        <li>
          Vzostup podzemných vôd v Hoyerswerde – numerický model pre výpočet
          odvodňovacích opatrení v starej časti mesta Hoyerswerda (spolupráca na
          projekte v Nemecku).
        </li>
        <li>
          Expertízne zhodnotenie vplyvu skládky odpadov Weiden-West na kvalitu
          podzemných vôd vodného zdroja Weiden (spolupráca na projekte v
          Nemecku).
        </li>
      </ul>
    </div>
  );
};

export default MedzinarodnaSpolupraca;
