import React from "react";
import ImageCard from "../components/ImageCard";
import "./global.css";
import reportPDF from "../assets/geo_kspv_2015.pdf";
import reportPDF2 from "../assets/OZP_KSPV.pdf";
import reportPDF3 from "../assets/EIA_KSPV.pdf";

const images = [
  {
    image: "/assets/geo_kspv_2015.jpg",
    title: "Geologické oprávnenie",

    link: reportPDF, // Použitie importovaného PDF súboru
    isPDF: true,
  },
  {
    image: "/assets/OZP_KSPV.jpg",
    title: "Ochrana prírody",

    link: reportPDF2, // Použitie importovaného PDF súboru
    isPDF: true,
  },
  {
    image: "/assets/EIA_KSPV.jpg",
    title: "EIA",

    link: reportPDF3, // Použitie importovaného PDF súboru
    isPDF: true,
  },
];

const Opravnenia = () => {
  return (
    <div>
      <h2>Oprávnenia</h2>
      <div className="gallery">
        {images.map((item, index) => (
          <ImageCard
            key={index}
            image={item.image}
            title={item.title}
            description={item.description}
            link={item.link}
            isPDF={item.isPDF}
          />
        ))}
      </div>
    </div>
  );
};

export default Opravnenia;
