import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/image_white_transparent.png";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const dokumentyRef = useRef(null);
  const referencieRef = useRef(null);
  const navigate = useNavigate();

  const handleClick = () => {
    setClick(!click);
    document.body.classList.toggle("menu-open", !click);
  };

  const closeMobileMenu = () => {
    setClick(false);
    setDropdownOpen(null);
    document.body.classList.remove("menu-open");
  };

  const toggleDropdown = (dropdownName) => {
    setDropdownOpen((prev) => (prev === dropdownName ? null : dropdownName));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dokumentyRef.current &&
        !dokumentyRef.current.contains(event.target)
      ) {
        setDropdownOpen(null);
      }
      if (
        referencieRef.current &&
        !referencieRef.current.contains(event.target)
      ) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo} alt="Logo" className="navbar-logo-img" />
          KSPV
        </Link>

        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>

        <ul className={`nav-menu ${click ? "active" : ""}`}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              O spoločnosti
            </Link>
          </li>

          {/* Dokumenty Dropdown */}
          <li className="nav-item" ref={dokumentyRef}>
            <div
              className="nav-links"
              onClick={() => toggleDropdown("dokumenty")}>
              Dokumenty{" "}
              <i
                className={`fas fa-caret-${
                  dropdownOpen === "dokumenty" ? "up" : "down"
                }`}
              />
            </div>
            <ul
              className={`dropdown-menu ${
                dropdownOpen === "dokumenty" ? "show" : ""
              }`}>
              <li className="dropdown-item">
                <Link
                  to="/publikacie"
                  className="dropdown-links"
                  onClick={closeMobileMenu}>
                  Publikácie
                </Link>
              </li>
              <li className="dropdown-item">
                <Link
                  to="/zborniky"
                  className="dropdown-links"
                  onClick={closeMobileMenu}>
                  Zborníky
                </Link>
              </li>
            </ul>
          </li>

          {/* Referencie Dropdown */}
          <li className="nav-item" ref={referencieRef}>
            <div
              className="nav-links"
              onClick={() => toggleDropdown("referencie")}>
              Referencie{" "}
              <i
                className={`fas fa-caret-${
                  dropdownOpen === "referencie" ? "up" : "down"
                }`}
              />
            </div>
            <ul
              className={`dropdown-menu ${
                dropdownOpen === "referencie" ? "show" : ""
              }`}>
              <li className="dropdown-item">
                <Link
                  to="/konferencie"
                  className="dropdown-links"
                  onClick={closeMobileMenu}>
                  Konferencie
                </Link>
              </li>
              <li className="dropdown-item">
                <Link
                  to="/spolupraca"
                  className="dropdown-links"
                  onClick={closeMobileMenu}>
                  Spolupráca
                </Link>
              </li>
              <li className="dropdown-item">
                <Link
                  to="/projekty"
                  className="dropdown-links"
                  onClick={closeMobileMenu}>
                  Projekty
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              to="/opravnenia"
              className="nav-links"
              onClick={closeMobileMenu}>
              Oprávnenia
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/kontakty"
              className="nav-links"
              onClick={closeMobileMenu}>
              Kontakty
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
