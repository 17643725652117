import React from "react";
import "./Footer.css";

function Footer() {
  const currentYear = new Date().getFullYear(); // Získanie aktuálneho roku
  const creationYear = 2025; // Rok vytvorenia stránky

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <a href="/"> O spoločnosti</a>
          <a href="/publikacie">Publikácie</a>
          <a href="/zborniky">Zborníky</a>
          <a href="/projekty">Projekty</a>
          <a href="/spolupraca">Spolupráca</a>
          <a href="/konferencie">Konferencie</a>
          <a href="/opravnenia">Oprávnenia</a>
          <a href="/kontakty">Kontakty</a>
          {/* <a href="/ochrana-osobnych-udajov">Ochrana osobných údajov</a>
          <a href="/podmienky-pouzivania">Podmienky používania</a> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
