// Publikacie.js
import React from "react";
import "./Publikacie.css";
import reportPDF from "../assets/VDG-25_1_2.pdf";
const Publikacie = () => {
  const openLink = (url) => {
    if (url) window.open(url, "_blank");
  };

  return (
    <div className="publikacie-container">
      <h2>Publikácie</h2>

      <ul className="projekty-list">
        <li>
          <h3>Mucha, I. - Paulíková, E. - Rodák, D., 1990</h3>
          Grundwasserregulierung in der Südslowakei - numerische Experimente als
          Problemlösung. Zborník z kongresu “Umweltwissenschaftliche Fachtage -
          Bedrohtes Wasser”. Technische Universität Graz, Erzherzog Johann
          Universität, Forschungsgeselshaft Joanneum Ges.m.b.H, Graz,
          10.-12.9.1990, s. 123-128.
        </li>
        <li>
          <h3>Mucha, I. - Paulíková, E. - Rodák, D., 1991</h3>K numerickej
          simulácii regulovania a prúdenia podzemných vôd. Vodohosp. čas., 39,
          1991, č. 3-4, s. 250-259.
        </li>
        <li>
          <h3>Mucha, I. - Paulíková, E. - Rodák, D. - Hlavatý, Z., 1993</h3>
          Grundwasserregulierung in der Südslowakei - numerische Experimente als
          Problemlösung. Zborník z kongresu “Umweltwissenschaftliche Fachtage -
          Bedrohtes Wasser”. Technische Universität Graz, Erzherzog Johann
          Universität, Forschungsgeselshaft Joanneum Ges.m.b.H, Graz,
          10.-12.9.1990, s. 123-128.
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/blue/11kap/11kap.htm"
            )
          }>
          <h3>Hlavatý, Z. - Cambel, B., 1995</h3>
          <strong>
            Changes in the soil moisture regime in the aeration zone after
            setting the Gabčíkovo hydroelectric power structures into operation.
            Zborník “Gabčíkovo Part of the Hydroelectric Power Project –
            Environmental Impact Review”. Faculty of Natural Sciences, Comenius
            University, Bratislava, s. 87-118.{" "}
          </strong>
        </li>

        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/blue/07kap/07kap.htm"
            )
          }>
          <h3>Rodák, D. - Banský, L., 1995</h3>
          <strong>
            Changes in the ground water regime on the right side of the Danube
            after setting Gabčíkovo hydropower structures into operation.
            Zborník “Gabčíkovo part of the Hydroelectric power project -
            environmental impact review”. Faculty of Natural Sciences, Comenius
            University, Bratislava, s. 57-64.
          </strong>
        </li>

        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/blue/09kap/09kap.htm"
            )
          }>
          <h3>Rodák, D. - Mucha, I., 1995</h3>
          <strong>
            Current activities in the ground water quality monitoring.
            Monitoring wells, methods of sampling, in situ measurements. Zborník
            “Gabčíkovo part of the Hydroelectric power project - environmental
            impact review”. Faculty of Natural Sciences, Comenius University,
            Bratislava, s. 71-77.
          </strong>
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/blue/10kap/10kap.htm"
            )
          }>
          <h3>Rodák, D. - Ďurkovičová, J. - Michalko, J., 1995</h3>
          <strong>
            The use of stable oxygen isotopes as a conservative tracer in the
            infiltrated Danube river water. Zborník “Gabčíkovo part of the
            Hydroelectric power project - environmental impact review”. Faculty
            of Natural Sciences, Comenius University, Bratislava, s. 79-85.{" "}
          </strong>
        </li>
        <li>
          <h3>
            Griffioen, J. - Engesgaard, P. - Brun, A. - Rodák, D. - Mucha, I. -
            Refsgaard, J. Ch., 1995
          </h3>
          Nitrate and Mn-chemistry in the alluvial Danubian Lowland aquifer,
          Slovakia. Groundwater Quality: Remediation and Protection. Proceedings
          of the Prague Conference, May 1995. IAHS Publ. no. 225, s. 87-96.
        </li>
        <li>
          <h3>Engesgaard, P. - Griffioen, J. - Rodák, D., 1995</h3>
          Redox-modellering af grundvand forurenet af overfladevand. ATV MODE,
          Forskningsprojekter vedrorende grundvandsforurening. Danmarks Tekniske
          Universitet, 26. Oktober 1995.
        </li>
        <li>
          <h3>
            Mucha, I. - Banský, Ľ. – Rodák, D. - Hlavatý, Z. – Gedeon, M. –
            Mažáriová, K., 1995
          </h3>
          Characteristics of surface and ground water regime in the impact area
          of the Gabčíkovo section of the G/N project (monitoring report). Reply
          submitted by the Slovak republic. Data and monitoring reports (1995),
          Vol. III, 20 June 1995. International court of justice,
          Gabčíkovo-Nagymaros project (Hungary/Slovakia), s. 1-18.
        </li>
        <li>
          <h3>Mucha, I. - Rodák, D. - Mažáriová, K., 1996</h3>
          Changes in the Surface Water and Ground Water Quality in the Gabčíkovo
          Reservoir and its Surroundings. Proceedings of International
          Conference on Aspects of Conflicts in Reservoir Development &
          Management. City University, London, UK, 3.-5. September 1996, s.
          593-600.
        </li>
        <li>
          <h3>Mucha, I. - Rodák, D. - Hlavatý, Z. - Banský, Ľ., 1997</h3>
          Environmental aspects of the design and construction of the Gabčíkovo
          Hydroelectric Power Project on the River Danube. Proceedings of
          International Symposium on Engineering Geology and the Environment,
          Greek national group of IAEG, Athens, Greece, 23.-27. June 1997.
          Balkema, Rotterdam, s. 2809-2817.
        </li>
        <li>
          <h3>Rodák, D., 1998</h3>
          Využitie metód numerického modelovania pri hodnotení kvality
          podzemných vôd. Zborník zo seminára “Hydrogeochémia´98”. Katedra
          hydrogeológie PriF UK, Bratislava, s. 132-147.
        </li>
        <li>
          <h3>
            Refsgaard, H.R. – Sorensen, H.R. – Mucha, I. - Rodák, D. – Hlavatý,
            Z. – Banský, Ľ. – Kľúčovská, J. – Topoľská, J. – Takáč, J. – Košč,
            V. – Enggrob, H.G. – Engesgaard, P. – Jensen, J.K. – Fiselier, J. –
            Griffioen, J. – Hansen, S., 1998
          </h3>
          An Integrated Model for the Danubian Lowland – Methodology and
          Applications. Water Resources Management, 12. Kluwer Academic
          Publishers, Netherlands, s. 433-465.
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/green/index.html"
            )
          }>
          <h3>
            Mucha, I. - Kocinger, D. - Hlavatý, Z. - Rodák, D. - Banský, Ľ. –
            Kučárová, K. – Kúrová, E., 1999
          </h3>
          <strong>
            Visit to the area of the Gabčíkovo hydropower project. Návšteva
            oblasti Vodného diela Gabčíkovo. Splnomocnenec vlády Slovenskej
            republiky pre výstavbu a prevádzku Sústavy vodných diel Gabčíkovo –
            Nagymaros, Konzultačná skupina PODZEMNÁ VODA s. r. o., Bratislava,
            103 s.
          </strong>
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/brown/chapters/ch5.htm"
            )
          }>
          <h3>
            Mucha, I. - Rodák, D. - Hlavatý, Z. - Banský, Ľ. – Kučárová, K.,
            1999
          </h3>
          <strong>
            Development of ground water regime in the area of the Gabčíkovo
            Hydroelectric Power Project. Slovak Geological Magazine, Vol. 5, No.
            1-2 (1999). Geological Survey of Slovak Republic, Bratislava, s.
            151-167.
          </strong>
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/green/index.html"
            )
          }>
          <h3>
            Mucha, I. – Hlavatý, Z. – Banský, Ľ. – Rodák, D. – Kúrová, E., 1999
          </h3>
          <strong>
            Basic Aspects of the Gabčíkovo Step Structures. Zborník “Gabčíkovo
            part of the Hydroelectric power project - environmental impact
            review” (Evaluation based on six year monitoring). Faculty of
            Natural Sciences, Comenius University, Bratislava, s. 67-92.
          </strong>
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/brown/index.html"
            )
          }>
          <h3>Hlavatý, Z. – Banský, Ľ. – Rodák, D. – Kučárová, K., 1999</h3>
          <strong>
            Surface water, ground water and soil moisture regime. Zborník
            “Gabčíkovo part of the Hydroelectric power project - environmental
            impact review” (Evaluation based on six year monitoring). Faculty of
            Natural Sciences, Comenius University, Bratislava, s. 119-142.
          </strong>
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/brown/index.html"
            )
          }>
          <h3>Kocinger, D. - Mucha, I - Hlavatý, Z. - Kučárová, K., 1999</h3>
          <strong>
            Development after putting the Gabčíkovo step into operation. Zborník
            “Gabčíkovo part of the Hydroelectric power project - environmental
            impact review” (Evaluation based on six year monitoring). Faculty of
            Natural Sciences, Comenius University, Bratislava, s. 35-66.
          </strong>
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/brown/index.html"
            )
          }>
          <h3>Mucha, I. - Hlavatý, Z. - Kozová, M., 1999</h3>
          <strong>
            Ecological part of the framework agreement between the Government of
            the Republic of Hungary and the Government of the Slovak Republic on
            implementation principles of the International Court of Justice
            Judgement. Zborník “Gabčíkovo part of the Hydroelectric power
            project - environmental impact review” (Evaluation based on six year
            monitoring). Faculty of Natural Sciences, Comenius University,
            Bratislava, s. 93-118.
          </strong>
        </li>
        <li>
          <h3>Mucha, I. – Rodák, D. – Hlavatý, Z. – Banský, Ľ., 2002</h3>
          Vplyv vodného diela Gabčíkovo na prírodné prostredie. Zborník z
          konferencie s medzinárodnou účasťou - XXVIII. Priehradné dni 2002,
          Bratislava, 4.-6. jún 2002, s. 406-412.
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/NATO/nato.htm"
            )
          }>
          <h3>Mucha, I. – Rodák, D. – Hlavatý, Z. – Banský, Ľ., 2002</h3>
          <strong>
            Groundwater quality processes after bank infiltration from the
            Danube at Čunovo. Proceedings of the NATO Advanced Research Workshop
            on Riverbank Filtration: Understanding Contaminant Biochemistry and
            Pathogen Removal, Tihany, Hungary, 5-8 September 2001. In Ray, C.
            (ed), Kluwer Academic Publishers, The Netherlands, s. 177-219.
          </strong>
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/yellow/index.htm"
            )
          }>
          <h3>
            Hlavatý, Z. - Rodák, D. in Lisický, M. J. – Mucha, I. (eds), 2003
          </h3>
          <strong>
            Optimalizácia vodného režimu ramennej sústavy v úseku Dunaja
            Dobrohošť – Sap z hľadiska prírodného prostredia. Splnomocnenec
            vlády Slovenskej republiky pre výstavbu a prevádzku Sústavy vodných
            diel Gabčíkovo - Nagymaros, Konzultačná skupina Podzemná voda
            s.r.o., Bratislava, 2003, p. 205.
          </strong>
        </li>
        <li>
          <h3>
            Mucha, I. - Kocinger, D. - Hlavatý, Z. - Rodák, D. - Banský, Ľ. -
            Lakatosová, E. - Kučárová, K., 2004
          </h3>
          Vodné dielo Gabčíkovo a prírodné prostredie. Súhrnné spracovanie
          výsledkov slovenského a maďarského monitoringu v oblasti vplyvu VD
          Gabčíkovo. Splnomocnenec vlády Slovenskej republiky pre výstavbu a
          prevádzku Sústavy vodných diel Gabčíkovo - Nagymaros, Konzultačná
          skupina PODZEMNÁ VODA s.r.o., Bratislava, 413 s.
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/Nato_06/nato.htm"
            )
          }>
          <h3>Mucha, I. - Banský Ľ. - Hlavatý, Z. - Rodák, D., 2006</h3>
          <strong>
            Impact of Riverbed Clogging – Colmatation – on Ground Water. In
            Hubbs S., A., (ed.) Riverbank Filtration Hydrology NATO Science
            Series IV. Erth and Environmental Sciences, Vol. 60, Springer, 43 -
            72.
          </strong>
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/old.gabcikovo.gov.sk/doc/moson/index.htm"
            )
          }>
          <h3>Mucha, I. - Lisický, M. J. (eds), 2006</h3>
          <strong>
            Slovak-Hungarian Environmental Monitoring on the Danube 1995-2005.
            Danube Monitoring Scientific Conference, Mosonmagyaróvár, Hungary,
            25-26 May 2006. Splnomocnenec vlády Slovenskej republiky pre
            výstavbu a prevádzku Sústavy vodných diel Gabčíkovo - Nagymaros,
            Konzultačná skupina Podzemná voda s.r.o., Bratislava, 2006, p. 298
            s.
          </strong>
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink(
              "https://gabcikovo.gov.sk/uploads/monitorovacie_spravy/SEA_info_web.pdf"
            )
          }>
          <h3>Rodák, D. - Banský Ľ., 2012</h3>
          <strong>
            Návrhy na riešenie starého koryta Dunaja v úseku Čunovo – Sap.
            Zborník príspevkov k odbornej konferencii pri príležitosti 20.
            výročia SVD G-N. Vodohospodárska výstavba, š. p., Bratislava, s.
            102-104.
          </strong>
        </li>
        <li>
          <h3>Rodák, D. – Hlavatý, H. - Banský Ľ., 2017</h3>
          Vodné dielo Gabčíkovo a prírodné prostredie. Zborník príspevkov. k
          odbornej konferencii pri príležitosti 25. výročia prevádzky SVD G-N.
          Vodohospodárska výstavba, š. p., Bratislava, s. 53-55.
        </li>
      </ul>
    </div>
  );
};

export default Publikacie;
