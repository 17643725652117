import React from "react";
import "./global.css"; // Import CSS pre štýlovanie

const Konferencie = () => {
  return (
    <div className="konferencie-container">
      <h2>Medzinárodné konferencie a študijné pobyty </h2>
      <ul className="konferencie-list">
        <li>
          European Research Input to River Basin Management, Brusel, Belgicko.
        </li>
        <li>
          International Conference from Conflict to Co-operation in
          International Water Resources Management: Challenges and
          Opportunities, Conflict Assessment and Resolution for Reservoir
          Systems, Delft, NL
        </li>
        <li>
          Modelling in Groundwater Resource Studies, Kuala Lumpur, Malajzia
        </li>
        <li>
          The Study on the Sustainable Groundwater Resources and Environmental
          Management for the Langat Basin, Kuala Lumpur, Malajzia
        </li>
        <li>
          Uferfiltrat, Hochschule fur Technik und Wirtschaft, Drážďany, Nemecko
        </li>
        <li>
          Aktuelle Arbeiten der Grundwasserforschung und –applikation, 6.
          Dresdner Grundwasserforschungstage, Drážďany, Nemecko
        </li>
        <li>
          3-D modelovanie prúdenia a transportu znečisťujúcich látok v podzemnej
          vode, Wissenschaftliche Schule Zunker-Busch-Luckner, Dresdner
          Grundwasserforschungszentrum e.V., Drážďany, Nemecko
        </li>
        <li>
          International symposium on engineering geology and the environment,
          Atény, Grécko
        </li>
        <li>
          International conference on aspects of conflicts in reservoir
          development & management, City University, Londýn, UK
        </li>
        <li>
          DHI Software User Conference and DHI Agent Seminar, Horsholm, Denmark
        </li>
        <li>
          Course on ground water chemistry and pollution, Vrije Universiteit,
          Amsterdam, NL
        </li>
        <li>
          Advanced Course in ground water modelling, Tempus Course No. 4, JEP
          4363-9213, Granada, Španielsko
        </li>
        <li>
          Geochemické modelovanie oxidačno-redukčných procesov, Technische
          Hydraulik und Ingenieurhydrologie, Gesamthochschule Kassel -
          Universität, Nemecko
        </li>
        <li>
          Short Course on Advanced Modelling of Groundwater Chemistry,
          International Institute for Hydraulic and Environmental Engineering,
          International ground water modeling center, Delft, NL
        </li>
        <li>
          Geochemické modelovanie a terénny výskum, TNO Institute of Applied
          Geoscience, Delft, NL
        </li>
        <li>
          Databázy, GIS-ové aplikácie a terénne metódy prieskumu kvality
          podzemných vôd, Technical University of Denmark, Department of Geology
          and Geotechnical Engineering, Lyngby, DK
        </li>
        <li>
          XXIX IAH kongres Hydrogeology and land use management, Bratislava, SK
        </li>
        <li>Danubian Lowland – Ground Water Model, Častá-Papiernička, SK</li>
        <li>
          Conference on the EU Strategy for the Danube Region: Transport, energy
          and environmental issues, Vienna (Austria) – Bratislava (Slovakia)
        </li>
        <li>
          Slovensko-maďarský úsek Dunaja a priľahlé ramenné sústavy -
          potenciálne opatrenia za účelom revitalizácie, Šamorín-Čilistov, SK
        </li>
      </ul>
    </div>
  );
};

export default Konferencie;
