import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Referencie from "./pages/Referencie";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Zborniky from "./pages/Zborniky";
import Spolupraca from "./pages/Spolupraca";
import Konferencie from "./pages/Konferencie";
import Projekty from "./pages/Projekty";
import Publikacie from "./pages/Publikacie";
import Footer from "./components/Footer";
import Opravnenia from "./pages/Opravnenia";
import "./pages/global.css";
function App() {
  return (
    <Router>
      <Navbar /> {/* Navigačný panel */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/opravnenia" element={<Opravnenia />} />
        <Route path="/kontakty" element={<Contact />} />
        <Route path="/publikacie" element={<Publikacie />} />
        <Route path="/zborniky" element={<Zborniky />} />
        <Route path="/projekty" element={<Projekty />} />
        <Route path="/spolupraca" element={<Spolupraca />} />
        <Route path="/konferencie" element={<Konferencie />} />
      </Routes>
      <Footer /> {/* Pridanie Footer komponentu */}
    </Router>
  );
}

export default App;
