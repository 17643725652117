import React from "react";
import "./global.css"; // Import CSS pre štýlovanie

const Projekty = () => {
  const openLink = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="projekty-container">
      <h2>Významné projekty na Slovensku</h2>

      <h3>Problematika SVD G-N</h3>
      <ul className="projekty-list">
        <li
          className="clickable-item"
          onClick={() => openLink("https://gabcikovo.gov.sk/")}>
          Odborná poradenská činnosť pre{" "}
          <strong>
            Splnomocnenca vlády SR pre výstavbu a prevádzku SVD G-N
          </strong>
          . Odborné práce, štúdie a expertízy v súvislosti s riešením
          problematiky SVD G-N pred MSD v Haagu a rokovaniami s maďarskou
          stranou o naplnení Rozsudku.
        </li>
        <li>
          Zhodnotenie výsledkov monitorovania zložiek prírodného prostredia v
          území ovplyvnenom VD Gabčíkovo a metodický návrh spôsobu celoplošného
          monitorovania vplyvu VD Gabčíkovo na životné prostredie.
        </li>
        <li>
          Monitorovanie prírodného prostredia v oblasti vplyvu VD Gabčíkovo
          podľa vodoprávneho rozhodnutia.
        </li>
        <li>
          Strategické posudzovanie vplyvov Sústavy vodných diel Gabčíkovo -
          Nagymaros na životné prostredie. Monitorovanie prírodného prostredia v
          úseku Dunaja Bratislava - Budapešť pre potreby delegácií vlád SR a MR
          a ich expertných pracovných skupín v súvislosti s rokovaniami o
          implementácii rozsudku MSD vo veci SVD G-N. Matematické modelovanie
          prúdenia povrchových a podzemných vôd. Vyhodnotenie uvažovaných
          riešení koryta Dunaja v úseku Čunovo – Sap.
        </li>
        <li>
          Zostavenie matematického modelu prúdenia povrchových a podzemných vôd
          v oblasti vplyvu VD Gabčíkovo.
        </li>
        <li>
          Mechanizmus samočistiacich procesov pri brehovej infiltrácii a kvalita
          podzemnej vody vodných zdrojov.
        </li>
        <li>
          Procesy tvorby zásob a kvality podzemných vôd v príbrežnej zóne
          Dunaja, monitoring hydrogeochemických procesov v podzemných vodách
          využívaných pre vodárenské účely a zostavenie modelu prúdenia a
          kvality podzemných vôd pri brehovej infiltrácii.
        </li>
        <li>
          Optimalizácia dokončenia vodného diela Gabčíkovo na území ČSFR z
          hľadiska vplyvov na podzemnú vodu.
        </li>
        <li
          className="clickable-item"
          onClick={() =>
            openLink("https://gabcikovo.gov.sk/index.php?page=Monitorovanie")
          }>
          Koordinácia a vyhodnocovanie spoločného slovensko-maďarského{" "}
          <strong>monitoringu prírodného prostredia</strong>.
        </li>
        <li>
          Zásady pre vodný režim a simuláciu záplav v ľavostrannej ramennej
          sústave Dunaja. Vyhodnotenie údajov z doplňujúceho monitorovania
          vodnej zložky životného prostredia počas umelých záplav v ľavostrannej
          ramennej sústave Dunaja.
        </li>
        <li>Štúdia zabezpečenia kvality vody v štrkoviskách pri Vojke.</li>
        <li>
          Odbery vzoriek pôd a sedimentov dunajských náplavov pre účely
          zhodnotenia obsahu a možnosti migrácie kontaminujúcich látok v
          podzemných vodách využívaných pre vodárenské účely.
        </li>
        <li>
          Vplyv ťažby štrkov v lokalite Podunajské Biskupice – Lieskovec na
          vodárenské využívanie príbrežnej zóny na ľavej strane Dunaja.
        </li>
        <li>
          Dočasné uvedenie VD Gabčíkovo do prevádzky na území ČSFR podľa
          alternatívy "C". Veľkopokus na overenie tesnenia dna stavebnej jamy
          zhutňovaním pri výstavbe VE Čuňovo, návrh, riadenie a modelová
          interpretácia hydrodynamických skúšok.
        </li>
        <li>
          Štúdia prúdenia podzemných vôd a sufóznych javov počas výstavby a
          zakladania stavebnej jamy v Čunove, modelové riešenie prúdenia
          podzemnej vody pre zakladanie vodnej elektrárne, plavebnej komory a
          hate.
        </li>
        <li>
          Prieskum prúdenia vody v priestore zakladania plavebných komôr VD
          Gabčíkovo modelovaním, návrh opatrení a vyhodnotenie meraní počas
          prevádzky a dotesňovania plavebných komôr.
        </li>
      </ul>

      <h3>Zdroje pitnej a úžitkovej vody</h3>
      <ul className="projekty-list">
        <li>
          Vodárenský zdroj Gabčíkovo – vyhodnotenie monitoringu a prognóza
          vývoja kvality vody. Výpočet využiteľného množstva podzemnej vody,
          návrh optimálneho využívania zdroja a spresnenie ochranných pásiem
          matematickým modelovaním. Matematický model šírenia sa znečistenia v
          oblasti vodárenského zdroja. Posúdenie vplyvu novej výstavby a návrh
          ochranných opatrení.
        </li>
        <li>
          Hydrogeologický prieskum s výpočtom využiteľného množstva podzemnej
          vody pre vodárenský zdroj v Okoči.
        </li>
        <li>
          Hydrogeologický prieskum s výpočtom využiteľného množstva podzemnej
          vody pre prevádzku teplárne a výrobu elektrickej energie pre
          spoločnosť TERMONOVA a.s. v Novej Dubnici.
        </li>
        <li>
          Hydrogeologický prieskum s výpočtom využiteľného množstva podzemnej
          vody pre prevádzku chladiacich zariadení v závode na výrobu
          svetlometov pre osobné a nákladné automobily spoločnosti HELLA
          Slovakia Lighting, s.r.o. v Kočovciach.
        </li>
        <li>
          Hydrogeologický prieskum s výpočtom množstiev podzemných vôd na
          lokalite Zohor pre zdroje úžitkovej vody spoločnosti Volkswagen
          Slovakia, a.s. - matematické modelovanie prúdenia podzemnej vody.
        </li>
        <li>
          Matematický model priestorového prúdenia podzemných vôd pre potreby
          upresnenia pásiem hygienickej ochrany vodárenského zdroja Rusovce,
          Ostrovné lúčky, Mokraď.
        </li>
        <li>Vodárenský zdroj Šamorín - posúdenie návrhu ochranných pásiem.</li>
        <li>
          Posúdenie možných vplyvov MVE Žiar nad Hronom na režim podzemných vôd
          v oblasti vodárenského zdroja VEOLIA Utilities Žiar nad Hronom.
        </li>
        <li>
          Vlčie Kúty - štúdia ochrany vodného zdroja, numerické modelovanie
          prítoku vody k studniam.
        </li>
      </ul>

      <h3>Minerálne vody</h3>
      <ul className="projekty-list">
        <li>
          Návrh postupu prác pre určenie režimu termominerálnych vôd v
          žriedlovej štruktúre Piešťany s cieľom revízie ochranných pásiem
          prírodných liečivých zdrojov v žriedlovej lokalite Piešťany.
        </li>
        <li>
          Určenie vplyvu výstavby tunela Diel na podzemné vody v oblasti kúpeľov
          Nimnica metódami modelovania.
        </li>
        <li>
          Posúdenie predpokladaných vplyvov exploatácie uhlia v 12. ťažobnom
          poli DP Nováky na prírodné liečivé zdroje v Bojniciach.
        </li>
        <li>
          Podrobný hydrogeologický prieskum geotermálnej vody s výpočtom
          využiteľného množstva vo Vlčanoch.
        </li>
      </ul>

      <h3>Režim povrchových a podzemných vôd</h3>
      <ul className="projekty-list">
        <li>
          Dolný Váh – riešenie vodohospodárskych a hydromelioračných pomerov,
          veľkopokus regulácie hladín podzemných vôd.
        </li>
        <li>
          Sprietočnenie, regulácia prietokov a hladín v Chorvátskom ramene.
        </li>
        <li>
          Vývoj hladinového režimu podzemných vôd v oblasti medzi Malým Dunajom
          a Malými Karpatmi - komplexné riešenie regulovania hladinového režimu
          povrchových a podzemných vôd v záujmovom území.
        </li>
        <li>
          Hydrogeologický posudok pre vsakovanie zrážkových vôd, výpočet
          predpokladaných objemov a času vsakovania, vrátane návrhu technického
          riešenia pre areál prekladiska v Čiernej nad Tisou.
        </li>
        <li>
          Hydrogeologický posudok pre odvodnenie spevnených plôch vojenskej
          železničnej rampy a vsakovanie zrážkových vôd v Dražovciach pri Nitre.
        </li>
      </ul>

      <h3>Environmentálne záťaže</h3>
      <ul className="projekty-list">
        <li>
          Podrobný hydrogeologický prieskum v oblasti odkaliska ZSNP, a.s. Žiar
          nad Hronom. Vyhodnotenie a optimalizácia hladinového režimu podzemných
          vôd s využitím metód matematického modelovania. Návrh na zaradenie
          vybudovaných čerpacích studní do systému hydraulickej ochrany. Odborný
          geologický dohľad pri sanácii podzemnej vody v priestore Kysličnikárne
          – Paraboly.
        </li>
        <li>
          Posúdenie produktovodného systému v úseku Slovnaft – Kľačany z
          hľadiska podzemných vôd a vplyvu na prírodné prostredie.
        </li>
        <li>
          Numerické modelovanie prúdenia podzemných vôd pre potreby hodnotenia
          šírenia sa znečistenia z odpadového kanála Chemko Strážske.
        </li>
        <li>
          Posúdenie možnosti čerpania a reinfiltrácie podzemných vôd v závode
          Banner Baterie SR, s.r.o. na lokalite Priemyselný park Žitný Ostrov,
          Kostoľné Kračany.
        </li>
        <li>
          Skládka odpadov OFZ v Istebnom – interpretácia čerpacej skúšky,
          matematické modelovanie účinnosti tesniacej steny a systému
          odvodňovacích objektov v areáli závodu.
        </li>
        <li>
          Vodné dielo Žilina - zachytávanie znečistených podzemných vôd
          Východného priemyslového pásma Žiliny, vplyv prelievanej podzemnej
          steny na režim podzemných vôd a kvalitu vody vo Váhu, posúdenie vplyvu
          náhradného biokoridoru na hladiny podzemnej vody.
        </li>
        <li>
          Zhodnotenie znečistenia podzemných vôd a zemín v lokalite VSŽ a.s. a
          odhad predpokladaných sanačných nákladov.
        </li>
        <li>
          Podrobný hydrogeologický prieskum kvality podzemnej vody v spoločnosti
          Eastern Sugar Slovensko, a. s. v Dunajskej Strede.
        </li>
        <li>
          Vyhodnotenie kvality podzemnej vody v lokalite jazierka Pivnička,
          Ivanka pri Dunaji. Návrh monitorovacích prác a odborné hydrogeologické
          posúdenie vývoja kvality podzemnej a povrchovej vody po realizovaní
          sanačných opatrení.
        </li>
      </ul>
    </div>
  );
};

export default Projekty;
