import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./global.css";
import logo from "../assets/image.jpg"; // Import loga
import pd from "../assets/Podzemnavoda4.jpg";
import pd1 from "../assets/obr3-5-druhy.jpg";
import pd2 from "../assets/Obr3-8-treti.jpg";
import pd3 from "../assets/Podzemnavoda5.jpg";
import pd4 from "../assets/Obr5-9a-stvrty.jpg";
import pd5 from "../assets/Podzemnavoda8.jpg";
import pd6 from "../assets/Podzemnavoda7.jpg";
import pd7 from "../assets/Podzemnavod3.jpg";
import pd8 from "../assets/Podzemnavoda2.jpg";
import pd9 from "../assets/Podzemnavoda10.jpg";
import pd10 from "../assets/Untitled-6.jpg";
import pd11 from "../assets/Untitled-4.jpg";
import pd12 from "../assets/Untitled-3-Recovered.jpg";
import pd13 from "../assets/Untitled-1.jpg";
import pd14 from "../assets/Untitled-2.jpg";
import pd15 from "../assets/Untitled-3.jpg";
import pd16 from "../assets/Untitled-5.jpg";
import pd17 from "../assets/Untitled-4.png";
import pd18 from "../assets/Untitled-9.jpg";
import pd19 from "../assets/Untitled-11.jpg";
import pd20 from "../assets/Untitled-8.jpg";
const teamImages = [
  pd,
  pd1,
  pd2,
  pd3,
  pd4,
  pd5,
  pd6,
  pd7,
  pd8,
  pd9,
  pd10,
  pd11,
  pd12,
  pd13,
  pd14,
  pd17,
  pd15,
  pd20,
  pd16,
  pd18,
  pd19,
];

function Home() {
  return (
    <div className="page-container">
      <header>
        <h2>
          Konzultačná skupina PODZEMNÁ VODA <br /> spol. s r. o.
        </h2>
        <img src={logo} alt="Logo" className="header-logo" />
        <h3>GROUND WATER Consulting Ltd.</h3>
      </header>

      <section className="home-company-info">
        <p>
          Spoločnosť Konzultačná skupina PODZEMNÁ VODA spol. s r.o. (KSPV)
          vznikla v roku 1993 transformáciou špeciálneho odborného tímu
          pracovníkov Prírodovedeckej fakulty Univerzity Komenského v
          Bratislave. Od svojho vzniku spoločnosť poskytuje široký komplex
          konzultačných služieb v oblasti hydrogeológie a životného prostredia,
          zabezpečuje hydrogeologický prieskum a štúdie zamerané na ochranu a
          využitie podzemných a povrchových vôd, rozvoj a ochranu zdrojov
          pitnej, úžitkovej a minerálnej vody, vykonáva geologický prieskum
          znečistenia, posudzovanie vplyvov na životné prostredie,
          environmentálne monitorovanie a numerické modelovanie podzemných a
          povrchových vôd.
        </p>
        <p>
          Konzultačná skupina PODZEMNÁ VODA bola odbornou skupinou splnomocnenca
          vlády SR pre výstavbu a prevádzku Sústavy vodných diel Gabčíkovo -
          Nagymaros (SVD G-N) pre zabezpečenie jeho činnosti, pre vyhodnocovanie
          spoločného slovensko-maďarského monitorovania prírodného prostredia,
          ako aj pre prípravu odborných podkladov, návrhov a stanovísk pre
          potreby riešenia problematiky SVD G-N pred Medzinárodným súdnym dvorom
          v Haagu (MSD). Experti KSPV boli členmi stálej vládnej delegácie SR
          pre rokovania s Maďarskom o implementácii Rozsudku MSD a členmi
          slovenskej časti spoločnej slovensko-maďarskej pracovnej skupiny pre
          strategické environmentálne posúdenie projektu SVD G-N. Spoločnosť
          úspešne spolupracovala so zahraničnými odborníkmi na viacerých
          významných medzinárodných projektoch.
        </p>
      </section>

      <section className="team-info">
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          loop={true}
          pagination={{ clickable: true }}
          navigation>
          {teamImages.map((src, index) => (
            <SwiperSlide key={index}>
              <img src={src} alt={`Team ${index + 1}`} className="landscape" />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
}

export default Home;
